@import "../config"
@import "/styles/config"


.certificates-description
    position: relative
    display: block
    margin: 0 0 40px 0
    font: 22px/150% $font
    max-width: 920px
    +max-width($mobail)
        font: 14px/150% $font