@import "../config"
@import "/styles/config"

.navigation
    position: absolute
    margin: 0
    top: 40px
    width: 100%
    +max-width($mobail)
        display: none

    &__wrapper
        font: 400 14px/143% $font
        color: $color
        padding: 4px 8px
        background: rgba(255, 255, 255, 0.6)
        width: fit-content
        +max-width($tablet)
            padding: 2px 4px

