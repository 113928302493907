@import "../config"
@import "/styles/config"

.technicalExpertise
    padding-top: 0
    padding-bottom: 120px
    +max-width($mobail)
        padding-bottom: 40px

    &--dark
        padding-top: 60px
        padding-bottom: 60px
        background-color: $lightbg
        margin-bottom: 100px
        +max-width($tablet)
            margin-bottom: 60px
        +max-width($mobail)
            margin-bottom: 40px
            padding-top: 20px
            padding-bottom: 20px

    &__title
        +h2

    &__description
        font: 400 22px/32px $font
        margin-bottom: 40px
        max-width: 680px
        +max-width($mobail)
            font: 400 14px/21px $font
            margin-bottom: 32px