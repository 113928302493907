@import "../config"
@import '/styles/config'

.navigation
    position: relative
    display: inline-block
    font: 600 13px/24px $font
    color: $color
    text-transform: uppercase
    letter-spacing: 1px
    cursor: pointer
    transition: color .3s ease
    +max-width($desktop)
        font: 600 12px/24px $font

    &.active
        color: $hover