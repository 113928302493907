@import "../config"
@import "/styles/config"

.eventsList
    display: flex
    flex-wrap: wrap
    gap: 40px
    margin-bottom: 40px
    +max-width($tablet)
        gap: 12px
        margin-bottom: 20px
        a:nth-child(n + 4)
            display: none