@import "../config"
@import "/styles/config"

.communityListItem
    display: flex
    flex-direction: column
    gap: 12px
    +max-width($mobail)
        gap: 8px

    &:last-child:nth-child(2n+1)
        grid-column: 1 / 3
        +max-width($lg-tablet)
            grid-column: unset


    &__title
        text-transform: uppercase
        color: $main-color
        font: 600 16px/20px $font
        letter-spacing: 1px

    &__applications
        display: flex
        column-gap: 32px
        row-gap: 12px
        flex-wrap: wrap
        +max-width($mobail)
            gap: 12px

    &__application
        font: 600 32px/40px $font
        color: #2A2D33
        +max-width($lg-tablet)
            font: 600 24px/32px $font
        +max-width($mobail)
            font: 600 16px/16px $font