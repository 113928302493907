@import "../config"
@import "/styles/config"


.advantagesGrid
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 40px
    +max-width($tablet)
        gap: 12px
        grid-template-columns: repeat(2, 1fr)
    +max-width($mobail)
        grid-template-columns: 1fr


