@import "../config"
@import "/styles/config"

.bitrixEnterprise
    padding-top: 0
    padding-bottom: 120px
    +max-width($tablet)
        padding-bottom: 80px
    +max-width($mobail)
        padding-bottom: 40px


    &__wrapper
        background: #F6F8F9
        padding: 48px
        display: flex
        gap: 40px
        align-items: center
        +max-width($tablet)
            gap: 20px
        +max-width($mobail)
            flex-direction: column
            padding: 20px

    &__container
        +max-width($mobail)
            display: flex
            flex-direction: column
            align-items: center


    &__top
        display: flex
        gap: 20px
        align-items: center
        margin-bottom: 20px
        +max-width($mobail)
            gap: 10px
            margin-bottom: 12px


    &__icon
        +max-width($mobail)
            max-width: 32px
            max-height: 32px

    &__title
        font: 700 36px/43.2px $font
        color: #020F25
        +max-width($tablet)
            font: 700 26px/34px $font
        +max-width($mobail)
            font: 700 20px/24px $font


    &__bot
        display: flex
        gap: 40px
        margin-left: 72px
        align-items: center
        +max-width($tablet)
            margin-left: 0
        +max-width($mobail)
            flex-direction: column
            gap: 20px
            margin-bottom: 20px


    &__description
        font: 400 24px/36px $font
        padding-bottom: 32px
        display: flex
        flex-direction: column
        gap: 20px
        +max-width($tablet)
            font: 400 16px/130% $font
        +max-width($mobail)
            font: 400 14px/18.2px $font
            padding-bottom: 0
            gap: 12px


    &__button
        padding: 15px 32px
        text-transform: uppercase
        color: $white
        font: 700 16px/24px $font
        letter-spacing: 1px
        background-color: $main-color
        border-radius: 27px
        width: fit-content
        transition: color 0.3s ease, background-color 0.3s ease
        border: 1px solid $main-color
        margin-left: 72px

        &:visited
            color: $white

        &:hover
            color: $main-color
            background-color: $white

        +max-width($tablet)
            margin-left: 0
            font: 600 14px/19.6px $font
            letter-spacing: 0.8px
            padding: 8px 20px
            margin-bottom: 12px


    &__image
        max-width: 272px
        max-height: 201px
        +max-width($tablet)
            max-width: 200px
            max-height: 150px
        +max-width($mobail)
            max-width: 145px
            max-height: 107px