@import "../config"
@import "/styles/config"

.eventsListItem
    position: relative
    display: block
    max-width: 439px
    cursor: pointer
    height: max-content
    +max-width($desktop)
        width: calc(50% - 20px)
    +max-width($mobail)
        width: 100%

    &:hover
        .eventsListItem__title
            color: $main-color


    &__wrapper
        width: 100%
        background-color: $grey
        margin-bottom: 12px
        height: 270px
        +max-width($mobail)
            height: 178px

        img
            width: 100%
            height: 100%
            object-fit: cover


    &__title
        font: 400 24px/130% $font
        margin: 8px 0 12px
        color: $color
        transition: color 0.3s ease
        +max-width($mobail)
            font: 400 14px/17.5px $font


    &__description
        font: 400 16px/150% $font
        color: #A9A9A9
        text-decoration: none
        +max-width($mobail)
            font: 400 12px/18px $font