@import "../config"
@import "/styles/config"


.hire-team

    &__wrapper
        display: flex
        align-items: center
        justify-content: center

.button--mainHeader
    background: $btn-link
    color: $white
    font: 600 13px/185% $font
    letter-spacing: 1px
    border: 1px solid $btn-link
    padding: 11px 38px
    text-transform: uppercase
    border-radius: 27.5px
    transition: color 0.3s ease, background-color 0.3s ease
    min-width: 215px

    &:hover
        color: $btn-link
        background: $white
