@import "../config"
@import "/styles/config"


.checkboxLabel
    display: flex
    align-items: center
    position: relative
    padding: 0 25px
    transition: all .3s
    vertical-align: middle
    +max-width($mobail)
        padding: 0
        margin: 0 0 9px 0

    &:hover
        color: #8d9bb9

        input
            &:after
                border: 1px solid #8d9bb9


.checkboxInput
    width: 100%
    height: 100%
    opacity: 0
    top: 0
    left: 0
    position: absolute

    &:focus + i:after
        background-color: $white
        box-shadow: 0 0 2px 2px rgba(121, 39, 224, 0.25)

    &:checked + i:after
        background-color: $main-color
        border-color: $main-color

.checkboxDecor
    position: relative
    width: 20px
    height: 20px
    margin: 0 12px 0 0

    &:after
        content: ""
        display: block
        border-radius: 2px
        border: solid 1px #b5bbc7
        transition: all .3s
        width: 100%
        height: 100%
        position: absolute
        top: 50%
        transform: translateY(-50%)
        left: 0
        z-index: 10

.customCheck
    position: absolute
    top: 40%
    transform: scale(0.7) translateY(-50%)
    z-index: 10
