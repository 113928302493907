@import "../config"
@import "/styles/config"

.bannerCase
    position: relative
    margin: 0 0 60px 0
    +max-width($lg-tablet)
        margin: 0 0 40px 0
    +max-width($mobail)
        margin: 0 0 20px 0

    &__wrapper
        position: relative
        width: 100%
        height: 100%
        max-height: 680px

    &__img
        object-fit: cover
        width: 100%
        height: 100%
        max-height: 680px
        +max-width($mobail)
            min-height: 212px
            object-fit: cover