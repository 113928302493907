@import "../config"
@import "/styles/config"

.marketingCommunityCase
    position: relative
    background-color: #F6F8F9


.marketingCommunity
    color: $color

    &__title
        font: 700 60px/76px $font
        margin-bottom: 40px
        +max-width($xl-tablet)
            font: 700 45px/126% $font
        +max-width($tablet)
            font: 700 35px/126% $font
            margin-bottom: 24px
        +max-width($mobail)
            font: 700 24px/32px $font
            margin-bottom: 12px