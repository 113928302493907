@import "../config"
@import "styles/config"

.footer
    z-index: 997
    position: relative
    border-top: 1px solid $border
    padding: 45px 0
    background: $white
    +max-width($lg-tablet)
        padding: 30px 0 35px
    +max-width($mobail)
        padding: 25px 0 30px

    &-full__wrapper
        margin: 0 auto
        width: 100%
        max-width: $max-width
        min-width: $min-width
        +padding

    &--main
        background: $white


    &__wrapper
        position: relative
        margin: 0 -20px
        +max-width($lg-tablet)
            margin: 0 -15px


    &__grid
        position: relative
        display: flex
        justify-content: space-between
        align-items: center
        +max-width($lg-tablet)
            flex-direction: column
            justify-content: center


    &__item
        position: relative
        padding: 0 20px 0 0
        +max-width($lg-tablet)
            padding: 0 15px

        &--left
            width: (100%/9)*4
            +max-width($lg-tablet)
                width: 100%
                margin: 0 0 20px 0
            +max-width($mobail)
                margin: 0 0 15px 0


        &--right
            width: (100%/9)*7
            display: flex
            justify-content: flex-end
            +max-width($lg-tablet)
                width: 100%
                justify-content: center


.footer-info
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    margin: 0 -20px
    +max-width($lg-tablet)
        margin: 0 -15px
        justify-content: center

    &-company
        width: (100%/9)*7.3
        display: flex
        justify-content: space-between
        +max-width($lg-tablet)
            padding: 0 15px
            flex-direction: column
            justify-content: center

        &-item
            display: flex
            justify-content: space-between
            padding: 0 20px
            +max-width($lg-tablet)
                justify-content: center


        &-item-right
            position: relative
            display: flex
            align-items: center
            gap: 39px
            +max-width($desktop)
                padding: 0 15px
            +max-width($lg-tablet)
                gap: 0
                width: 100%
                flex-direction: column
                justify-content: center

    &__item
        position: relative
        display: flex
        align-items: center
        padding: 0 20px
        +max-width($lg-tablet)
            padding: 0 15px


        &--coop
            display: flex
            flex-direction: column
            align-items: flex-start
            +max-width($lg-tablet)
                display: none


    &__coop
        position: relative
        display: inline-block
        font: 600 16px/130% $font
        color: $color
        +max-width($desktop)
            font: 600 14px/130% $font
        +max-width($lg-tablet)
            font: 600 16px/130% $font


    &__link-wrap
        position: relative
        &:last-child
            margin: 0 0 0 60px
            +max-width($xl-desktop)
                margin: 0 0 0 40px
            +max-width($desktop)
                margin: 0 0 0 30px
            +max-width($lg-tablet)
                margin: 0 0 0 30px


        &--email
            +max-width($mobail)
                display: none



    &__phone-link
        position: relative
        display: inline-block
        font: 600 16px/130% $font
        color: $color
        +max-width($desktop)
            font: 600 14px/130% $font
        +max-width($lg-tablet)
            font: 600 16px/130% $font


    &__email-link
        position: relative
        display: inline-block
        font: 600 16px/130% $font
        color: $color
        +max-width($desktop)
            font: 600 14px/130% $font
        +max-width($lg-tablet)
            font: 600 16px/130% $font


    &__result
        display: block
        margin-top: 10px
        font: 14px/16px $font
        color: #7b7d83


.footer-right
    position: relative
    display: flex
    align-items: center
    margin: 0 30px 0 0
    +max-width($desktop)
        margin: 0 40px 0 5px
    +max-width($lg-tablet)
        margin: 0 45px
    +max-width($lg-tablet)
        flex-direction: column
    +max-width($mobail)
        margin: 0

    &__item
        position: relative
        padding: 0 30px
        +max-width($desktop)
            padding: 0 20px
        +max-width($lg-tablet)
            padding: 0 15px
            margin: 0 0 20px 0
        +max-width($mobail)
            margin: 0 0 15px 0
            padding: 0


        &--career
            padding: 0 20px 0 0
            +max-width($xl-desktop)
                padding: 0
            +max-width($desktop)
                padding: 0 10px 0 0
            +max-width($lg-tablet)
                padding: 0 10px
                text-align: center

        &--presentation
            padding: 0 0 0 20px
            +max-width($xl-desktop)
                padding: 0 0 0 10px
            +max-width($desktop)
                padding: 0 0 0 15px
            +max-width($mobail)
                padding: 0


        &--social
            +max-width($desktop)
                padding: 0 0 0 10px

        &--coop
            display: none
            +max-width($lg-tablet)
                display: block
                margin: 0



.footer-presentation
    position: relative
    display: flex
    align-items: center
    font: 600 16px/130% $font
    color: $color
    white-space: nowrap
    +max-width($desktop)
        font: 600 14px/130% $font
    +max-width($lg-tablet)
        font: 600 16px/130% $font

    img + span
        margin: 0 0 0 12px


    span
        position: relative
        display: inline-block



.footer-career
    position: relative
    display: inline-block
    font: 600 16px/130% $font
    color: $color
    +max-width($desktop)
        font: 600 14px/130% $font
    +max-width($lg-tablet)
        font: 600 16px/130% $font



















