@import "../config"
@import "styles/config"

.footer-social
    position: relative
    display: flex
    align-items: center
    +max-width($mobail)
        margin: 0 -5px


    &__item
        position: relative
        +max-width($mobail)
            padding: 0 5px


    &__link
        position: relative
        display: flex
        align-items: center
        justify-content: center
        transition: opacity .3s ease
        width: 60px
        min-width: 60px
        max-width: 60px
        +max-width($lg-tablet)
            width: 40px
            min-width: 40px
            max-width: 40px
            height: 40px


        &:hover
            opacity: .7


















