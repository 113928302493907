@import "../config"
@import "/styles/config"

.communityInfo
    display: grid
    grid-template-columns: repeat(2, 1fr)
    margin-top: 80px
    gap: 40px
    +max-width($lg-tablet)
        grid-template-columns: unset
        margin-top: 40px
        gap: 32px