@import "../config"
@import "/styles/config"


.serviceExpertise
    background-color: $lightbg
    padding-bottom: 100px
    +max-width($mobail)
        padding-bottom: 32px


    &__title
        +h2
