@import "../config"
@import "/styles/config"

.checkboxBlock
    margin: 0 0 34px 0
    +max-width($mobail)
        margin: 0 0 26px 0


    &__title
        margin-bottom: 24px
        +h2
        +max-width($mobail)
            margin: 0 0 12px 0

    &__list
        display: flex
        margin: 0 -25px
        +max-width($mobail)
            flex-direction: column
            margin: 0