@import "../config"
@import "/styles/config"

.outstaffList
    padding-bottom: 100px
    margin: 0
    +max-width($tablet)
        padding-bottom: 80px
    +max-width($mobail)
        padding-bottom: 40px

    $root: &

    &__wrapper
        display: flex
        flex-wrap: wrap
        gap: 40px
        transition: all 0.3s ease
        +max-width($tablet)
            flex-direction: column
        +max-width($mobail)
            gap: 12px


    &__teams
        padding: 34px
        border: 1px solid #E1E7EB
        width: calc(50% - 40px)
        align-items: flex-start
        display: flex
        flex-direction: column
        max-width: 560px
        cursor: pointer
        +max-width($tablet)
            width: 100%
        +max-width($mobail)
            padding: 12px

        &:hover
            #{$root}__teams-button
                color: $white
                background-color: $main-color


        &-subtitle
            font: 30px/133% $font
            font-weight: 600
            font-style: normal
            color: $color
            +max-width($mobail)
                font: 18px/125% $font
                font-weight: 600

        &-text
            font: 18px/150% $font
            font-weight: 400
            color: $color
            font-style: normal
            margin-top: 8px
            margin-bottom: 12px
            +max-width($mobail)
                font: 14px/150% $font
                margin-top: 4px
                margin-bottom: 8px

        &-list
            display: flex
            flex-wrap: wrap
            gap: 8px
            margin-bottom: 70px
            +max-width($mobail)
                margin-bottom: 20px


        &-item
            color: $color
            font: 600 12px/20px $font
            letter-spacing: 1px
            text-transform: uppercase


        &-button
            display: flex
            align-items: center
            padding: 14px 32px
            border: 1px solid $btn-link
            border-radius: 27.5px
            transition: color 0.3s ease, background-color 0.3s ease
            color: $btn-link
            letter-spacing: 1px
            font: 600 16px/22.4px $font
            text-transform: uppercase
            +max-width($mobail)
                padding: 8px 20px
                font: 600 14px/24px $font
                letter-spacing: 0.8px