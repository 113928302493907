@import "../config"
@import "/styles/config"

.history-block
    background: #2A2D33
    color: #FFFFFF
    overflow: hidden

    &__title
        color: #FFFFFF

    &__wrapper
        display: flex
        justify-content: space-between
        margin-bottom: 20px

.history-slider
    +max-width($mobail)
        display: block

    .headermenu
        position: initial

    &__container
        display: flex !important
        flex-direction: column
        row-gap: 10px

    &__year
        font: 700 60px/111% "proxima nova", sans-serif
        +max-width($desktop)
            font-size: 55px
        +max-width($tablet)
            font-size: 50px
        +max-width($mobail)
            font-size: 40px
        +max-width($sm-mobail)
            font-size: 40px


    &__wrapper
        display: flex
        flex-direction: column
        row-gap: 12px
        border-left: 4px solid $main-color
        padding-left: 20px


//slider styles
.history-slider :global(.slick-slide)
    opacity: 0.3
    width: 480px
    padding: 0 52.5px
    +max-width($mobail)
        width: 100%
        padding: 0

.history-slider :global(.slick-current)
    opacity: 1

.history-slider :global(.slick-list)
    overflow: visible
    margin-left: -52.5px
    +max-width($mobail)
        overflow: hidden
        margin-left: 0


.history-slider__controls
    display: flex
    position: absolute
    right: 255px
    z-index: 10
    +max-width($xl-desktop)
        right: 80px
    +max-width($mobail)
        right: 20px


    .classic-arrow
        //border: 2px solid $link
        background-color: #2A2D33

        &:hover
            background: $main-color


.history-slider :global(.slick-next)
    display: none !important

.history-slider :global(.slick-prev)
    display: none !important
