@import "../config"
@import "/styles/config"

.offerBody
    position: relative

    &__wrapper
        max-width: 1040px
        +max-width($xl-desktop)
            margin: 0

    &__title
        margin-bottom: 12px
        font: 700 3.2vw/126% $font
        color: $color
        padding: 0 0 20px 0
        +min-width(1920px)
            font-size: 60px
            white-space: nowrap
        +max-width($desktop)
            font-size: 45px
        +max-width($tablet)
            font-size: 35px
        +max-width($mobail)
            padding: 0
            font-size: 24px
            margin: 0 0 4px 0


    &__subtitle
        max-width: 610px
        font: 20px/140% $font
        margin: 0 0 40px 0
        +max-width($mobail)
            font: 14px/143% $font
            margin: 0 0 20px 0

        a
            display: inline-block
