@import "../config"
@import "/styles/config"


.reasonOutstaff
    display: flex
    flex-direction: column
    gap: 60px
    padding-bottom: 100px
    +max-width($lg-tablet)
        gap: 20px
        padding-bottom: 40px

    &__title
        color: $color
        margin-bottom: 40px
        font: 700 60px/76px $font
        +max-width($desktop)
            font-size: 45px
        +max-width($mobail)
            margin-bottom: 12px
            font: 700 24px/140% $font

    &__subtitle
        color: $color
        font: 600 30px/140% $font
        +max-width($lg-tablet)
            font: 600 20px/140% $font


    &__block
        display: flex
        flex-direction: column
        gap: 32px
        +max-width($lg-tablet)
            gap: 12px

        &:nth-child(1)
            gap: 0

        &__wrapper
            display: flex
            flex-direction: column
            gap: 20px
            +max-width($lg-tablet)
                gap: 12px

    &__text
        color: $color
        font: 400 22px/140% $font
        max-width: 920px
        +max-width($lg-tablet)
            font: 400 14px/140% $font

    &__list
        margin: 0

        li
            margin: 0 0 12px 0