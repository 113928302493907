@import "../config"
@import "/styles/config"

.header-menu
    div.header-menu__link
        cursor: pointer
        transition: color .3s ease


.header-menu
    position: relative

    &__wrapper
        position: relative
        margin: 0 -40px
        +max-width($desktop)
            margin: 0 -20px


    &__grid
        position: relative
        display: flex
        align-items: center


    &__item
        position: relative
        display: block
        padding: 0 30px
        +max-width($xl-desktop)
            padding: 0 15px
        +max-width($desktop)
            padding: 0 10px


    &__item-wrap
        position: relative
        display: block
        &:hover
            .header-menu__link
                color: $hover



    &__link
        position: relative
        display: inline-block
        font: 600 13px/24px $font
        color: $color
        text-transform: uppercase
        letter-spacing: 1px
        +max-width($desktop)
            font: 600 12px/24px $font

        &.active
            color: $hover


.header-menu__item-wrap
    &:hover
        .header-menu-down
            display: block
            &:before
                display: block


.header-menu-down
    position: absolute
    display: none
    padding: 10px 0 0 0
    width: 180px

    &--big
        width: 260px


    &:before
        content: ''
        position: absolute
        left: -10px
        right: 10px
        top: 20px
        bottom: -10px
        background: rgba(129, 129, 129, 0.3)
        filter: blur(54.3656px)
        border-radius: 5px
        box-shadow: 0 0 54.3656px rgba(129, 129, 129, 0.3)


    &__wrapper
        position: relative
        padding: 20px
        border-radius: 5px
        background: $white
        width: 100%


    &__item
        position: relative
        display: block


    &__item + &__item
        margin: 5px 0 0 0


    &__link
        position: relative
        display: inline-block
        text-transform: uppercase
        font: 600 14px/110% $font
        letter-spacing: 0.5px
        padding: 0 0 0 15px
        color: #2a2d33
        &:hover
            color: $hover

        &:before
            content: '–'
            color: inherit
            font-size: inherit
            line-height: 100%
            position: absolute
            left: 0
            top: 0

        &--icon
            &:after
                content: ''
                color: inherit
                font-size: inherit
                line-height: 100%
                background-image: url('/images/svg/header/blankLink.svg')
                background-position: 50% 50%
                background-repeat: no-repeat
                background-size: cover
                height: 12px
                width: 12px
                display: inline-block
                margin: 0 0 0 8px