@import "../config"
@import "/styles/config"

.classic-arrow
    cursor: pointer
    background-color: #2A2D33
    border: 2px solid $link
    width: 50px
    height: 50px
    border-radius: 50%
    transition: background-color .3s
    display: flex
    align-items: center
    justify-content: center
    margin: 0 0 0 10px !important
    transform: rotate(270deg)
    +max-width($mobail)
        height: 30px
        width: 30px

    &--gray
        background-color: #484e58

    &--left
        transform: rotate(90deg)
        margin: 0 !important
    svg
        width: 18px
        +max-width($mobail)
            width: 12px
        path
            transition: fill .3s
    &:hover
        background-color: $main-color
        svg
            path
                fill: $white


    &:disabled
        border: 2px solid #484e58
        pointer-events: none


        svg
            path
                fill: #484e58