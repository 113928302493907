@import "../config"
@import "/styles/config"

.cardListCases
    margin: 0 0 60px 0
    +max-width($tablet)
        margin: 0 0 24px 0
    +max-width($mobail)
        margin: 0 0 12px 0

    &--layout
        margin: 0 0 120px 0
        +max-width($tablet)
            margin: 0 0 80px 0
        +max-width($mobail)
            margin: 0 0 60px 0

    &__wrapper
        display: flex
        flex-direction: column


    &__title
        font: 700 48px/120% $font
        margin-bottom: 40px
        +max-width($lg-tablet)
            font: 700 36px/48px $font
        +max-width($mobail)
            font: 700 24px/140% $font
            margin-bottom: 12px


    &__subtitle
        font: 600 22px/150% $font
        max-width: 800px
        margin-bottom: 24px
        +max-width($lg-tablet)
            font: 600 18px/150% $font
        +max-width($mobail)
            font: 600 14px/150% $font
            margin-bottom: 12px


    &__list
        max-width: 1040px
        display: grid
        grid-template-columns: repeat(3, 1fr)
        gap: 40px
        +max-width($tablet)
            grid-template-columns: repeat(2, 1fr)
        +max-width($sm-mobail)
            grid-template-columns: repeat(1, 1fr)
            gap: 12px


    &__card
        display: flex
        flex-direction: column
        gap: 8px
        padding: 20px
        outline: 1px solid $grey-border
        border-radius: 12px

        &-text
            max-width: 280px
            font: 400 16px/150% $font
            +max-width($mobail)
                font: 400 13px/150% $font

            &--grow
                font: 400 18px/140% $font
                +max-width($mobail)
                    font: 400 15px/140% $font
