@import "../config"
@import "/styles/config"

.badgeList
    display: flex
    flex-wrap: wrap
    gap: 12px
    +max-width($mobail)
        gap: 4px


    &__item
        display: flex
        align-items: center
        gap: 12px
        padding: 8px 16px
        border: 1px solid #E1E7EB
        border-radius: 8px
        +max-width($mobail)
            gap: 8px
            padding: 4px 8px


    &__logo
        max-width: 32px
        max-height: 32px
        +max-width($mobail)
            max-width: 24px
            max-height: 24px


    &__name
        font: 400 22px/32px $font
        +max-width($mobail)
            font: 400 14px/21px $font
