@import "../config"
@import "/styles/config"

.applicationBlock
    display: flex
    flex-direction: column
    padding: 34px
    gap: 24px
    background-color: $lightbg
    width: 320px
    +max-width($lg-tablet)
        padding: 20px
        gap: 20px
    +max-width($sm-mobail)
        width: 100%

    &__top
        display: flex
        flex-direction: column
        gap: 8px


        &-title
            color: $color
            font: 600 30px/40px $font
            +max-width($lg-tablet)
                font: 600 20px/140% $font


        &-description
            color: $color
            font: 400 18px/150% $font
            +max-width($lg-tablet)
                font: 400 14px/140% $font


    &__bot
        display: flex


        &-button
            display: flex
            align-items: center
            padding: 14px 32px
            border-radius: 27.5px
            border: 1px solid $btn-link
            transition: color 0.3s ease, background-color 0.3s ease
            color: $btn-link
            letter-spacing: 1px
            font: 600 16px/22.4px $font
            text-transform: uppercase
            +max-width($lg-tablet)
                padding: 8px 20px
                font: 600 14px/24px $font
                letter-spacing: 0.8px


            &:hover
                background-color: $btn-link
                color: $white