@import "../config"
@import "/styles/config"


.advantagesMarketing
	padding-top: 0
	padding-bottom: 100px
	+max-width($mobail)
		padding-bottom: 40px

	&--blackbg
		padding: 60px 0
		background-color: $lightbg
		margin-bottom: 100px
		+max-width($mobail)
			padding: 40px 0
			margin-bottom: 40px

	&__title
		margin-bottom: 40px
		font: 700 60px/76px $font
		+max-width($desktop)
			font-size: 45px
		+max-width($mobail)
			margin-bottom: 12px
			font: 700 24px/140% $font