@import "../config"
@import "/styles/config"

.eventsByExperts
    padding-top: 0
    padding-bottom: 100px
    +max-width($mobail)
        padding-bottom: 40px

    &__wrapper
        display: flex
        flex-direction: column


    &__title
        +h2
        margin-bottom: 33px
        +max-width($mobail)
            margin-bottom: 12px

    &__link
        +max-width($mobail)
            align-self: center

    &__button
        border-radius: 27px
        border: 1px solid $main-color
        padding: 15px 20px
        text-transform: uppercase
        font: 600 14px/24px $font
        letter-spacing: 0.8px
        transition: color 0.3s ease, background-color 0.3s ease
        +max-width($mobail)
            padding: 8px 20px
            border-radius: 20px


        &:hover
            background-color: $main-color
            color: $white

