@import "../config"
@import "styles/config"
// Бургер меню
// ===========
.header-burger
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    z-index: 99

    &__wrap
        position: relative
        width: 20px
        height: 14px
        cursor: pointer

    &:hover
        .header-burger__global
            border-top: 2px solid $link


    &.active
        .header-burger
            &__global

                &--top
                    backface-visibility: hidden
                    top: calc(50% - 1px)
                    transform: rotate(45deg)
                    transition: .3s .3s

                &--middle
                    opacity: 0

                &--bottom
                    backface-visibility: hidden
                    top: calc(50% - 1px)
                    transform: rotate(-405deg)
                    transition: .3s .3s



    &__global
        backface-visibility: hidden
        position: absolute
        left: 0
        border-top: 2px solid $color
        width: 20px
        transition: .3s

        &--top
            top: 0


        &--middle
            top: 6px


        &--bottom
            top: 12px


.header__mobail-wrap
    position: absolute
    left: 0
    right: 0
    bottom: 0
    top: 0

.burger-menu
    position: absolute
    top: 0
    left: 0
    bottom: 0
    z-index: 998
    max-width: 580px
    width: 100%
    overflow-y: auto
    +max-width($tablet)
        max-width: none


    &__bg
        position: fixed
        -webkit-backdrop-filter: blur(3px)
        backdrop-filter: blur(3px)
        background-color: rgba(116, 118, 124, 0.5)
        top: 0
        bottom: 0
        right: 0
        left: 0
        z-index: 99
        display: none

.header__mobail-menu
    position: fixed
    left: -100%
    width: 100%
    top: 0
    bottom: 0
    min-width: 280px
    overflow: hidden
    z-index: 998
    opacity: 1
    transition: left .3s ease, opacity .5s ease
    +max-width($tablet)
        transition: right .3s ease, opacity .5s ease
        left: auto
        right: -100%

    .header-burger
        position: absolute
        z-index: 999
        top: 35px
        left: 30px
        +max-width($xl-desktop)
            top: 38px
        +max-width($desktop)
            top: 30px
        +max-width($tablet)
            top: 15px
            left: auto
            right: 40px
            height: 30px
        +max-width($mobail)
            right: 20px
            top: 15px


.header__mobail-menu.active
    left: 0
    opacity: 1
    transition: left .3s ease, opacity .5s ease
    +max-width($tablet)
        transition: right .3s ease, opacity .5s ease
        left: auto
        right: 0

    .burger-menu
        opacity: 1
        transition: opacity .5s ease

        &__bg
            display: block
            animation-name: animBlock
            animation-duration: 0.2s
            animation-fill-mode: both
            animation-timing-function: ease
            animation-delay: 0.2s