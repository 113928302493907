@import "../config"
@import "/styles/config"


.site-cards
	position: relative
	display: block
	padding: 0
	margin: 100px 0
	+max-width($mobail)
		margin: 40px 0

	&__wrapper
		position: relative
		width: 100%

	&__title
		margin-bottom: 40px
		+max-width($mobail)
			margin-bottom: 24px

	&__grid
		position: relative
		display: grid
		grid-template-columns: repeat(3, 1fr)
		+max-width($desktop)
			grid-template-columns: repeat(1, 1fr)
		+max-width($mobail)
			gap: 8px

	&__buttons
		display: flex
		flex-direction: column
		gap: 13px
		flex: 0 0 auto
		+max-width($mobail)
			gap: 12px
	&__button
		font: 30px/140% $font
		color: $color
		font-weight: 700
		font-style: normal
		+max-width($mobail)
			font: 20px/140% $font
			font-weight: 700

	&__active
		color: $main-color

	&__content
		display: flex
		gap: 28px
		+max-width($desktop)
			flex-direction: column
			gap: 20px