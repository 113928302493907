@import "../config"

.wrapper
    transition: opacity 1s ease
    overflow: hidden
    -webkit-overflow-scrolling: touch

.visible
    opacity: 1


.hidden
    opacity: 0

.slideUp
    animation: aniUp 1s


@keyframes aniUp
    from
        transform: translateY(100%)
    to
        transform: translateY(0)

