@import "../config"
@import "/styles/config"


.costServices
	margin: 100px 0
	padding-top: 60px
	padding-bottom: 60px
	+max-width($mobail)
		padding-top: 20px
		padding-bottom: 20px
		margin: 40px 0

	&__title
		margin-bottom: 40px
		font: 700 60px/76px $font
		+max-width($desktop)
			font-size: 45px
		+max-width($mobail)
			margin-bottom: 12px
			font: 700 24px/140% $font

	&__desc
		margin-bottom: 40px
		font: 400 22px/33px $font
		width: 66%
		+max-width($mobail)
			margin-bottom: 20px
			width: 100%
			font: 400 18px/140% $font

	&__button
		color: $white
		font-style: normal
		background-color: $main-color
		font: 18px/140% $font
		letter-spacing: 1px
		font-weight: 600
		padding: 14px 32px
		border-radius: 27.5px
		text-transform: uppercase
		cursor: pointer
		max-width: fit-content
		border: 1px solid $white
		transition: all 0.3s ease
		+max-width($mobail)
			padding: 8px 24px
			font: 14px/171% $font
			letter-spacing: 0.8px
			font-weight: 600

		&:hover
			background: $white
			color: $main-color
			border: 1px solid $main-color
			box-sizing: border-box