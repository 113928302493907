@import "../config"
@import "/styles/config"


.nextCase
    background-color: $lightbg
    padding: 54px 0
    +max-width($tablet)
        padding: 20px 0 40px 0

    &__wrapper
        display: flex
        position: relative
        max-width: 1180px
        margin: 0 auto
        +max-width($tablet)
            flex-direction: column
            gap: 40px
        +max-width($mobail)
            gap: 24px

        &:hover
            .nextCase__image
                transform: scale(1.02)

    &__link
        display: block
        margin: 0 32px 0 0
        flex-shrink: 0
        position: relative
        +max-width($tablet)
            width: 100%
            height: 100%
            max-width: 390px
            max-height: 288px
            margin: 0


    &__image
        max-width: 390px
        max-height: 288px
        transition: transform .3s ease
        width: 100%
        height: 100%


    &__info
        display: flex
        flex-direction: column
        justify-content: center
        max-width: $sm-mobail
        +max-width($sm-mobail)
            padding: 0


    &__over-title
        font: 600 12px/120% $font
        color: #84969c
        margin: 0 0 4px 0
        text-transform: uppercase
        letter-spacing: 1px
        +max-width($sm-mobail)
            font: 600 10px/120% $font


    &__title
        font: 600 40px/140% $font
        margin: 0 0 4px 0
        color: $color
        display: block
        +max-width($tablet)
            font: 600 28px/140% $font
        +max-width($sm-mobail)
            font: 600 20px/140% $font

        &:hover
            color: $link


    &__text
        font: 22px/150% $font
        color: $color
        +max-width($sm-mobail)
            font: 400 14px/140% $font

