@import "../config"
@import "/styles/config"

.modal
	&-success
		position: absolute
		top: 60px
		left: 50%
		transform: translate(-50%, 60px)
		background-color: white
		padding: 60px 40px
		z-index: 100
		border-radius: 12px
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.13)
		+max-width($tablet)
			width: 290px
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
			padding: 32px 24px
	&-container
	&-close
		display: flex
		align-items: center
		justify-content: center
		padding: 0 30px 0 0
		color: $link
		font: 20px/130% $font
		position: absolute
		top: 30px
		right: 20px
		cursor: pointer
		+max-width($tablet)
			top: 22px
			right: 12px
		&:before
			content: ''
			top: calc(50% - 7px)
			right: 0
			position: absolute
			height: 16px
			width: 20px
			background-image: url('https://ba5f0191-bf14-4ecb-8111-dfb7ed9d1e60.selstorage.ru/images/svg/hire-team-modal__cross.svg')
			background-position: 50% 50%
			background-size: contain
			background-repeat: no-repeat
	&-content
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		gap: 10px
	&-title
		font: 600 32px/125% $font
		color: $color
		+max-width($tablet)
			font-size: 24px
	&-description
		font: 400 20px/125% $font
		color: $color
		+max-width($tablet)
			font-size: 14px
			text-align: center