@import "../config"
@import "/styles/config"


.certificates
    position: relative
    display: block


    &__wrapper
        position: relative


    &__grid
        position: relative
        display: flex
        flex-wrap: wrap