@import "../config"
@import "/styles/config"

.expertisePanelContent
    display: flex
    flex-wrap: wrap
    gap: 12px

    &__title
        font: 600 20px/25px $font
        margin-bottom: 12px
        margin-top: 40px
        +max-width($mobail)
            margin-top: 20px

    &__card
        display: flex
        flex-direction: column
        width: 100%
        max-width: 920px


    &__content
        font: 400 22px/32px $font
        margin-top: 40px
        max-width: 920px
        +max-width($mobail)
            font: 400 14px/21px $font
            margin-top: 32px