@import "../config"
@import "/styles/config"

.wrapper
    display: flex
    flex-direction: column
    width: calc(100%/3)
    padding: 0 20px
    +max-width($mobail)
        width: auto
        padding: 0
        margin: 0 0 10px 0
        max-width: 400px

.textField
    width: 100%
    padding: 7px 15px
    border-radius: 2px
    border: 1px solid $grey
    background-color: $white
    font: 16px/150% $font
    +max-width($mobail)
        max-width: 400px

    &:disabled
        background: $grey

    &:focus
        outline: none


    &__title
        font: 16px/150% $font
        margin: 0 0 4px 0

.wrapperError
    .textField
        border-color: $error
        border-width: 2px
