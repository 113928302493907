@import "../config"
@import "/styles/config"

.expertisePanel


    &__tabs
        display: flex
        flex-wrap: wrap
        gap: 20px
        padding-bottom: 20px

        &-item
            font: 600 12px/20px $font
            color: #B5BBC7
            border: 1px solid transparent
            letter-spacing: 1px
            min-height: 28px
            border-radius: 14px
            transition: color .3s ease, border .3s ease
            min-width: auto
            padding: 4px 12px
            text-transform: uppercase

            &:hover
                color: $main-color


            &--active
                color: $main-color
                border: 1px solid $main-color