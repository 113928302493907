@import "../config"
@import "/styles/config"

.developmentListItem
    padding: 34px
    border: 1px solid #E1E7EB
    width: calc(50% - 40px)
    align-items: flex-start
    display: flex
    flex-direction: column
    max-width: 560px
    cursor: pointer
    +max-width($tablet)
        width: 100%
    +max-width($mobail)
        padding: 12px

    $root: &

    &:hover
        #{$root}__button
            color: $white
            background-color: $main-color


    &__subtitle
        font: 30px/133% $font
        font-weight: 600
        font-style: normal
        color: $color
        +max-width($mobail)
            font: 18px/125% $font
            font-weight: 600


    &__text
        font: 18px/150% $font
        font-weight: 400
        color: $color
        font-style: normal
        margin-top: 8px
        margin-bottom: 12px
        +max-width($mobail)
            font: 14px/150% $font
            margin-top: 4px
            margin-bottom: 8px


    &__button
        display: flex
        align-items: center
        padding: 14px 32px
        border: 1px solid $btn-link
        border-radius: 27.5px
        transition: color 0.3s ease, background-color 0.3s ease
        color: $btn-link
        letter-spacing: 1px
        font: 600 16px/22.4px $font
        text-transform: uppercase
        +max-width($mobail)
            padding: 8px 20px
            font: 600 14px/24px $font
            letter-spacing: 0.8px