@import "../config"
@import "/styles/config"


.text
	margin: 100px 0
	+max-width($mobail)
		margin: 40px 0

	&__left
		width: 66%
		+max-width($lg-tablet)
			width: 100%

	&__title
		margin-bottom: 40px
		font: 700 60px/76px $font
		+max-width($desktop)
			font-size: 45px
		+max-width($mobail)
			margin-bottom: 12px
			font: 700 28px/125% $font

	&__paragraf
		margin-bottom: 24px
		font: 400 22px/33px $font
		+max-width($mobail)
			margin-bottom: 12px
			font: 400 18px/140% $font

	&__button
		color: $main-color
		font-style: normal
		font: 16px/140% $font
		font-weight: 600
		cursor: pointer
		transition: all 0.3s ease
		+max-width($mobail)
			font: 14px/171% $font
			letter-spacing: 0.8px
			font-weight: 600

		&:hover
			color: $link-hover

	&__Content
		gap: 12px
		font: 400 22px/33px $font
		+max-width($mobail)
			font: 400 18px/140% $font
			margin-top: 8px
			gap: 8px
			align-items: baseline
		ol li
			margin-bottom: 12px
		ol li::before
				position: relative
				color: $main-color
				min-width: 20px
				+max-width($mobail)
					min-width: 16px
		div
			font: 400 30px/140% $font
			margin-bottom: 12px
			+max-width($mobail)
				font: 400 24px/140% $font
				margin-top: 8px