@import "../config"
@import "/styles/config"


.advantagesGridItem
    display: flex
    flex-direction: column
    gap: 12px
    width: 100%
    padding: 32px
    background-color: $lightbg
    +max-width($lg-tablet)
        padding: 16px
        gap: 8px

    &--text-block
        padding: 32px 0
        +max-width($lg-tablet)
            padding: 16px 0


    &--reverse
        background-color: #fff


    &__icon
        min-width: 40px
        min-height: 40px
        +max-width($lg-tablet)
            min-width: 24px
            min-height: 24px

    &__index
        color: $main-color
        font: 600 30px/150% $font
        +max-width($lg-tablet)
            font: 600 20px/150% $font


    &__info
        display: flex
        flex-direction: column
        gap: 8px
        +max-width($lg-tablet)
            gap: 4px

    &__title
        color: $color
        font: 600 22px/150% $font
        +max-width($lg-tablet)
            font: 600 14px/140% $font

    &__text
        color: $color
        font: 400 22px/150% $font
        +max-width($lg-tablet)
            font: 400 14px/140% $font







