@import "../config"
@import "/styles/config"


.communityGrid
    display: grid
    grid-template-areas: "b1 b3 b6" "b1 b3 b6" "b1 b3 b6" "b1 b3 b6" "b1 b4 b6" "b1 b4 b6" "b1 b4 b7" "b2 b4 b7" "b2 b5 b7" "b2 b5 b7" "b2 b5 b7" "b2 b5 b7"
    grid-column-gap: 40px
    grid-row-gap: 40px
    +max-width($lg-tablet)
        display: flex
        flex-direction: column
        gap: 12px

    &--socials
        grid-template-areas: "b1 b4 b6" "b1 b4 b6" "b1 b4 b6" "b1 b4 b6" "b2 b4 b7" "b2 b4 b7" "b2 b5 b7" "b2 b5 b7" "b3 b5 b8" "b3 b5 b8" "b3 b5 b8" "b3 b5 b8"