@import "../config"
@import "/styles/config"

.caseResult
    margin: 0 0 120px 0
    +max-width($tablet)
        margin: 0 0 80px 0
    +max-width($mobail)
        margin: 0 0 60px 0

    &__title
        font: 700 48px/120% $font
        margin-bottom: 60px
        +max-width($lg-tablet)
            font: 700 40px/48px $font
            margin-bottom: 24px
        +max-width($mobail)
            margin-bottom: 12px
            font: 700 24px/140% $font