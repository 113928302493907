@import "../config"
@import "/styles/config"

.titleCaseBottom
    display: flex
    gap: 40px
    justify-content: space-between
    +max-width($lg-tablet)
        flex-direction: column

    &__left
        max-width: 680px
        width: 66%
        +max-width($mobail)
            width: 100%


    &__item
        display: flex
        flex-direction: column

        &-company
            font: 600 32px/150% $font
            +max-width($lg-tablet)
                font: 600 28px/150% $font
            +max-width($mobail)
                font: 600 20px/140% $font

        &-link
            margin: 8px 0 0 0
            +max-width($mobail)
                margin: 4px 0 0 0

            &-content
                font: 400 22px/150% $font
                +max-width($mobail)
                    font: 400 14px/140% $font

        &-text
            margin: 20px 0 0 0
            font: 400 22px/150% $font
            +max-width($lg-tablet)
                margin: 16px 0 0 0
            +max-width($mobail)
                margin: 12px 0 0 0
                font: 400 14px/140% $font

            br
                +max-width($xl-desktop)
                    display: none

    &__media-links
        display: flex
        gap: 77px
        margin: 20px 0 0 0
        +max-width($mobail)
            margin: 12px 0 0 0

.linksContainer
    display: flex
    gap: 40px


.right
    max-width: 440px
    width: 34%
    display: flex
    align-items: flex-start
    +max-width($lg-tablet)
        width: 100%
        max-width: fit-content

    .block
        display: flex
        flex-direction: column
        gap: 10px
        padding-left: 24px
        border-left: 4px solid $main-color

    .info
        font: 400 24px/140% $font
        +max-width($mobail)
            font: 400 18px/140% $font

    .img
        max-width: 224px
        max-height: 67px