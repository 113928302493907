@import "../config"
@import "/styles/config"


.certificates

    &__top
        display: flex
        flex-direction: column
        align-items: baseline


    &__item
        position: relative
        display: block
        width: (100%/10)*2
        min-height: 280px
        margin: 0 -1px -1px 0
        +max-width($desktop)
            width: (100%/12)*6
            min-height: auto
        +max-width($sm-mobail)
            width: (100%/12)*12

        &--less
            width: (100%/8)*2
            +max-width($desktop)
                width: (100%/10)*6
            +max-width($sm-mobail)
                width: 100%


    &__link
        position: relative
        display: flex
        width: 100%
        height: 100%
        flex-direction: column
        justify-content: space-between
        padding: 20px
        overflow: hidden
        background: $white
        transition: background .3s ease

        &:before
            content: ''
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            border: 1px solid #eceef2

        &:hover
            background: #e1e7eb


    &__direction
        position: relative
        display: block
        font: 600 10px/150% $font
        color: #b5bbc7
        letter-spacing: 0.5px
        text-transform: uppercase
        margin: 0 0 50px 0
        +max-width($desktop)
            margin: 0 0 30px 0


    &__img
        position: relative
        display: flex
        align-items: center
        height: 75px
        min-width: 60px
        margin: 0 0 20px 0
        +max-width($mobail)
            height: 50px

        img
            max-height: 75px
            +max-width($mobail)
                max-height: 50px


    &__name
        position: relative
        display: block
        font: 18px/150% $font
        max-width: 240px
        padding: 0 20px 0 0
        color: #b5bbc7
        +max-width($desktop)
            font: 16px/150% $font

        &-black
            font: 18px/150% $font
            color: $black
            +max-width($desktop)
                font: 16px/150% $font


