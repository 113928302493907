@import "../config"
@import "/styles/config"


.words-container
    margin: 0 0 52px 0
    +max-width($mobail)
        margin: 0 0 40px 0


    &__small-title
        color: $color
        font: 600 32px/125% $font
        margin: 0 0 20px 0
        +max-width($tablet)
            font: 600 25px/125% $font
        +max-width($mobail)
            font: 600 20px/140% $font
            margin: 0 0 4px 0

    &__textarea
        height: 120px
        width: 809px
        border-radius: 2px
        border: solid 1px $grey
        background-color: $white
        transition: background-color 0.3s ease
        margin: 0 0 20px 0
        padding: 15px
        font: 16px/150% $font
        +max-width($tablet)
            width: 100%
            height: 80px
            margin: 0 0 12px 0
        +max-width($mobail)
            width: 100%

    &:disabled
        background: $grey

    &:focus
        outline: none

    &__add-file
        font: 600 12px/167%


    &__add-file-input
        width: 0.1px
        height: 0.1px
        opacity: 0
        overflow: hidden
        position: absolute
        z-index: -1


    &__add-file-label
        width: 200px
        text-transform: uppercase
        font: 600 12px/167% $font
        display: flex
        align-items: center
        cursor: pointer


    &__add-file-img
        width: 16px
        margin: 0 12px 0 0


.contacts-container
    margin: 0 0 34px 0
    +max-width($mobail)
        margin: 0 0 10px 0

    &__small-title
        color: $color
        font: 600 32px/125% $font
        margin: 0 0 20px 0
        +max-width($tablet)
            font: 600 25px/125% $font
        +max-width($mobail)
            font: 600 20px/140% $font
            margin: 0 0 4px 0

    &__inputs
        margin: 0 -16px 0 -16px
        display: flex
        +max-width($mobail)
            flex-direction: column
            margin: 0

    &__input-title
        font: 16px/150% $font
        margin: 0 0 4px 0

    &__label
        display: flex
        flex-direction: column
        width: calc(100% / 3)
        padding: 0 20px
        +max-width($mobail)
            width: auto
            padding: 0
            margin: 0 0 10px 0
            max-width: 400px


    &__input
        width: 100%
        padding: 7px 15px
        border-radius: 2px
        border: solid 1px #b5bbc7
        background-color: $white
        font: 16px/150% $font
        +max-width($mobail)
            max-width: 400px


.submit-container
    margin: 0 0 60px 0
    display: flex
    align-items: center
    +max-width($mobail)
        align-items: flex-start
        flex-direction: column-reverse


    &__submit-btn
        text-transform: uppercase
        border-radius: 27.5px
        letter-spacing: 1px
        font: 600 16px/140% $font
        background-color: $main-color
        color: $white
        border: 1px solid $main-color
        transition: color 0.3s ease, background-color 0.3s ease
        margin: 0 40px 0 0 !important
        padding: 14px 53px !important
        +max-width($mobail)
            padding: 8px 24px !important
            letter-spacing: 0.8px
            font: 600 14px/171% $font
            border-radius: 20px


        &:hover
            color: $main-color
            background-color: $white


    &__submit-text
        max-width: 500px
        font: 14px/143% $font
        +max-width($mobail)
            margin: 0 0 24px 0
