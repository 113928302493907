@import "../config"
@import "/styles/config"

.customWrapper
    margin: 0 0 60px 0
    +max-width($tablet)
        margin: 0 0 24px 0
    +max-width($mobail)
        margin: 0 0 12px 0

    br
        +max-width($xl-desktop)
            display: none

    & > div
        & > div + div
            margin: 40px 0 0 0

    &--layout
        margin: 0 0 120px 0
        +max-width($tablet)
            margin: 0 0 80px 0
        +max-width($mobail)
            margin: 0 0 60px 0

.textInnerCase
    display: flex
    gap: 40px
    justify-content: space-between
    align-items: flex-start
    +max-width($lg-tablet)
        flex-direction: column

    &__left
        position: relative
        max-width: 920px
        width: 66%

        +max-width($lg-tablet)
            width: 100%

        &-title
            font: 600 30px/150% $font
            +max-width($lg-tablet)
                font: 600 24px/150% $font
            +max-width($mobail)
                font: 600 20px/150% $font

            & + div
                margin-top: 40px
                +max-width($lg-tablet)
                    margin-top: 24px
                +max-width($mobail)
                    margin-top: 12px

            & + ol
                margin-top: 24px !important
                +max-width($mobail)
                    margin-top: 12px !important


        &-mark
            font: 400 24px/140% $font
            padding: 10px
            border-radius: 4px
            background-color: $purple-light-bg
            width: fit-content


        & > div
            font: 400 22px/150% $font
            +max-width($mobail)
                font: 400 14px/150% $font

            & > h4
                margin: 40px 0 0 0
                color: $color
                font: 600 24px/140% $font
                +max-width($lg-tablet)
                    margin: 12px 0 0 0
                    font: 600 20px/140% $font
                +max-width($mobail)
                    font: 600 18px/140% $font

            & > p
                margin: 0 0 24px 0
                +max-width($tablet)
                    margin: 0 0 8px 0

                &:last-child
                    margin: 0

            & > small
                color: #9CA2AD
                font: 400 18px/140% $font
                +max-width($tablet)
                    font: 400 12px/140% $font

        & > div + div, div + ul, div + ol, ul + div
            margin: 40px 0 0 0
            +max-width($lg-tablet)
                margin: 12px 0 0 0

        & > div
            & > h4 + ul
                margin: 12px 0 0 0 !important

        & > ol, ul
            margin: 0

            li
                font: 400 22px/150% $font
                padding: 0 0 0 32px
                +max-width($mobail)
                    font: 400 14px/150% $font

                &:not(:last-child)
                    margin-bottom: 12px
                    +max-width($mobail)
                        margin-bottom: 8px

                &:last-child
                    margin: 0

                &:before
                    color: $main-color


    &__right
        max-width: 440px
        width: 34%
        display: flex
        align-items: flex-end
        +max-width($lg-tablet)
            width: 100%
            max-width: fit-content


        &-block
            display: flex
            flex-direction: column
            padding-left: 24px
            border-left: 4px solid $main-color

            & > h4
                font: 600 22px/150% $font
                +max-width($mobail)
                    font: 600 16px/140% $font

            & > p
                font: 400 24px/140% $font
                padding: 0
                margin: 0
                +max-width($mobail)
                    font: 400 16px/140% $font
