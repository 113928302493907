@import "../config"
@import "/styles/config"


.expertiseList
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 100px
    grid-row-gap: 60px
    +max-width($lg-tablet)
          grid-column-gap: 40px
          grid-row-gap: 20px
    +max-width($mobail)
          display: flex
          flex-wrap: wrap
          grid-row-gap: 32px