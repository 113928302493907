@import "../config"
@import "/styles/config"

.expertiseListItem
    display: flex
    flex-direction: column
    gap: 16px

    &__label
        font: 600 30px/133% $font
        color: $color
        letter-spacing: 1px
        +max-width($lg-tablet)
            font: 600 26px/132% $font
            display: flex
            flex-direction: column
            align-items: flex-start
        +max-width($tablet)
            font: 600 22px/132% $font
        +max-width($mobail)
            font: 600 20px/132% $font


    &__raiting
        font: 400 20px/120% $font
        margin-top: 4px
        display: flex
        +max-width($tablet)
            flex-direction: column

    &__href
        padding-right: 4px

        span
            transition: color 0.3s ease
            color: $link
            cursor: pointer

            &:hover
                color: $link-hover


    &__text
        color: #566369
        margin-left: 4px
        +max-width($tablet)
            margin-left: 0

    &__images
        display: flex
        flex-wrap: wrap
        gap: 20px
        +max-width($desktop)
            gap: 10px


    &__img
        display: flex
        justify-content: center
        align-items: center
        width: 140px
        max-height: 140px
        transition: box-shadow 0.3s ease

        &:hover
            box-shadow: 0 4px 11px rgba(154, 168, 189, 0.13)
