@import "../config"
@import "/styles/config"


.layoutImage
    object-fit: cover
    width: 100%
    height: 100%
    max-height: 680px
    margin-bottom: 60px
    +max-width($tablet)
        margin-bottom: 40px
    +max-width($mobail)
        transform: scale(1.3)
        padding: 40px 0
        margin-bottom: 30px
        object-fit: contain