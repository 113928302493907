@import "../config"
@import "/styles/config"


.big-main-title
    margin: 0 0 40px 0
    font: 700 70px/109% $font
    +max-width($lg-tablet)
        font: 700 60px/109% $font
    +max-width($tablet)
        font: 700 50px/109% $font
        margin: 0 0 30px 0
    +max-width($mobail)
        font: 700 40px/109% $font
        margin: 0 0 20px 0