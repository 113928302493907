@import "../config"
@import "/styles/config"


.collapseListItem--active

    .triggerItem__arrow:after
        border-color: $grey-slider
        top: 2px
        transform: rotate(225deg)


.collapseListItem


    &__inner
        display: flex
        flex-direction: column
        gap: 16px
        +max-width($lg-tablet)
            gap: 8px

    &__inner-solution
        margin-left: 52px
        +max-width($mobail)
            margin-left: 0


        ul
            margin: 0


        p
            margin: 0
            color: $color
            font: 400 22px/150% $font
            +max-width($lg-tablet)
                font: 400 14px/140% $font


            b
                font-weight: 700


        ul li
            color: $color
            font: 400 22px/150% $font
            margin: 0 0 12px 0
            +max-width($lg-tablet)
                margin: 0 0 8px 0
                font: 400 14px/140% $font


.triggerItem
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 12px
    gap: 40px
    cursor: pointer


    &__title
        max-width: 828px
        color: $color
        font: 600 30px/150% $font
        +max-width($lg-tablet)
            font: 600 20px/140% $font


    &__right
        display: flex
        flex-direction: column
        align-items: flex-start

    &__img
        width: 40px
        height: 40px
        margin-right: 12px
        +max-width($mobail)
            width: 24px
            height: 24px
            margin-right: 8px
            img
                width: 24px
                height: 24px
                max-width: none

    &__left
        display: flex
        align-items: center
        +max-width($mobail)
            align-items: flex-start

    &__arrow
        display: flex
        justify-content: center
        align-items: center
        width: 40px
        height: 40px
        border-radius: 50%
        background-color: $lightbg


        &:after
            content: ''
            position: relative
            top: -2px
            border: solid $main-color
            border-width: 0 2px 2px 0
            display: inline-block
            padding: 5px
            transition: transform 0.3s ease, border-color 0.3s ease
            transform: rotate(45deg)

