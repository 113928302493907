@import "../config"
@import "/styles/config"

.marketingExpertiseListItem
    max-width: 440px
    min-height: 582px
    position: relative
    +max-width($lg-tablet)
        max-width: 400px
    +max-width($xl-tablet)
        max-width: 380px
    +max-width($mobail)
        min-height: 384px
        max-width: 100%
        width: 100%

    $root: &

    &:hover
        #{$root}__info, #{$root}__text, #{$root}__description
            color: $white

        #{$root}__image-hover
            opacity: 1

        #{$root}__svg
            opacity: 0

        #{$root}__svg-hover
            opacity: 1

        #{$root}__link
            color: $white
            background: $main-color

        #{$root}__btn-text
            &:before
                border-color: $white

    &__image-hover
        opacity: 0
        min-height: 582px
        height: 100%
        transition: opacity 0.8s ease-in-out
        +max-width($mobail)
            opacity: 1
            min-height: 384px
            width: 100%

    &__item
        position: absolute
        top: 0
        display: flex
        flex-direction: column
        padding: 144px 34px 40px 34px
        border: 1px solid #B5BBC7
        color: #2A2D33
        cursor: pointer
        justify-content: space-between
        max-width: 440px
        min-height: 582px
        width: 100%
        transition: 0.8s ease
        +max-width($lg-tablet)
            max-width: 400px
        +max-width($xl-tablet)
            max-width: 380px
        +max-width($mobail)
            min-height: 384px
            height: 100%
            padding: 88px 22px 26px 22px
            max-width: 100%
            color: $white

    &__inner
        position: relative


    &__svg
        max-width: 72px
        max-height: 72px
        opacity: 1
        transition: opacity 0.3s ease
        +max-width($mobail)
            max-width: 48px
            max-height: 48px
            opacity: 0


    &__svg-hover
        position: absolute
        opacity: 0
        transition: opacity 0.3s ease
        top: 0
        +max-width($mobail)
            max-width: 48px
            max-height: 48px
            opacity: 1


    &__info
        margin-top: 24px
        margin-bottom: 12px
        font: 600 100px/100px $font
        +max-width($xl-tablet)
            font: 600 75px/75px $font
        +max-width($mobail)
            font: 600 55px/55px $font
            margin-top: 15px

        small
            font: 600 30px/30px $font
            +max-width($mobail)
                font: 600 20px/20px $font

    &__text
        font: 600 30px/40px $font
        margin-top: 12px
        +max-width($mobail)
            margin-top: 8px
            font: 600 20px/26px $font


    &__description
        font: 400 20px/25px $font
        +max-width($mobail)
            font: 400 14px/17.5px $font


    &__link
        display: flex
        align-items: center
        justify-content: center
        gap: 8px
        width: fit-content
        background: #E9EDF0
        border-radius: 100px
        padding: 6px 10px
        color: #3E464F
        margin-top: 32px
        transition: color 0.8s ease, background-color 0.8s ease
        +max-width($mobail)
            margin-top: 15px
            background: $main-color
            color: $white

    &__btn-text
        display: flex
        align-items: center
        gap: 8px
        font: 600 12px/12px $font
        letter-spacing: 0.05em
        text-transform: uppercase
        padding-top: 3px
        +max-width($mobail)
            color: $white

        &:before
            content: ''
            border: solid $color
            border-width: 0 2px 2px 0
            display: inline-block
            padding: 2px
            transition: border-color 0.3s ease
            transform: rotate(-45deg)
            -webkit-transform: rotate(-45deg)

            +max-width($mobail)
                border-color: $white

    &__item-container
        display: flex
        flex-direction: column
        justify-content: flex-start
        border: 1px solid #B5BBC7
        padding: 240px 34px 40px 34px
        color: #9CA6B9
        max-width: 440px
        min-width: 440px
        min-height: 582px
        transition: 0.8s ease
        +max-width($lg-tablet)
            max-width: 400px
            min-width: 400px
        +max-width($xl-tablet)
            max-width: 380px
            min-width: 380px
        +max-width($mobail)
            max-width: 290px
            min-width: 290px
            min-height: 384px
            padding: 0 22px
            justify-content: center
        +max-width(346px)
            min-width: 263px

    &__next-text
        font: 600 100px/100px $font
        margin-top: 24px
        margin-bottom: 12px
        +max-width($xl-tablet)
            font: 600 75px/75px $font
        +max-width($mobail)
            font: 600 50px/50px $font
            margin-top: 38px

    &__next-description
        font: 600 30px/40px $font
        +max-width($xl-tablet)
            margin-top: 10px
        +max-width($mobail)
            font: 600 20px/26px $font
            margin-top: 5px
