@import "../config"
@import "/styles/config"

.contentCaseMiddle
    margin: 0 0 60px 0
    +max-width($lg-tablet)
        margin: 0 0 32px 0

    &__item
        display: flex
        flex-direction: column


    &__wrapper
        display: flex
        flex-direction: column
        max-width: 680px
        gap: 20px
        +max-width($mobail)
            gap: 12px

        &-name
            color: #597289
            font: 600 12px/166% $font
            letter-spacing: 1px
            text-transform: uppercase
            +max-width($mobail)
                font: 600 10px/120% $font

        &-details
            font: 600 24px/150% $font
            +max-width($mobail)
                font: 600 14px/140% $font