@import "../config"
@import "/styles/config"

.titleCaseHeader
    display: flex
    flex-direction: column
    max-width: 920px
    gap: 40px
    margin-bottom: 60px
    +max-width($lg-tablet)
        gap: 32px
        margin-bottom: 32px
    +max-width($mobail)
        gap: 12px

    &__title
        font: 700 64px/120% $font
        +max-width($lg-tablet)
            font: 700 52px/120% $font
        +max-width($mobail)
            font: 700 28px/125% $font

    &__subtitle
        font: 400 30px/140% $font
        +max-width($lg-tablet)
            font: 400 28px/140% $font
        +max-width($mobail)
            font: 400 18px/140% $font