@import "../config"
@import "/styles/config"

.communityGridItem
    padding: 40px
    background: $white
    border-radius: 12px
    max-width: 440px
    +max-width($lg-tablet)
        display: flex
        flex-direction: column
        gap: 30px
        max-width: unset
    +max-width($mobail)
        padding: 12px
        gap: 10px

    &__svg
        max-width: 64px
        max-height: 64px
        +max-width($mobail)
            max-width: 52px
            max-height: 52px


    &__text
        font: 400 28px/35px $font
        margin-top: 10px
        +max-width($mobail)
            font: 400 16px/20px $font
