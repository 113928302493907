@import "../config"
@import "/styles/config"


.common__title
    color: $color
    font: 700 3.2vw/126% $font
    padding: 0 0 20px
    +max-width($desktop)
        font-size: 45px
    +max-width($tablet)
        font-size: 35px
    +max-width($mobail)
        font-size: 24px
        margin: 0 0 4px
        padding: 0